import React from "react";
import "./App.css";
import Home from "./components/home/Home";
import Footer from "./components/footer/Footer";
import ScrollUp from "./components/scrollup/ScrollUp";

const App = () => {
  localStorage.setItem("path", "home");
  return (
    <>
      <main className="main">
        <Home />

      </main>
      <Footer />
      <ScrollUp />
    </>
  );
};

export default App;
