import React from "react";

const Data = () => {
  if (window.innerWidth <= 992) {
    return (

      <div className="home__container">
        <div className="home__wrap">
          <div className="home__texts">
            <div className="home__title">Communibility</div>
            <div className="home__description__box">
              <div className="home__description">
                A community that gives you the <span>ability to earn</span>
              </div>
            </div>
          </div>

          <div className="nav__item__gold">
            <a
              href="https://discord.gg/6b2xYkvTWH"
              className="join-beta-button"
            >
              <i className="uil"></i>JOIN THE BETA
            </a>
          </div>
        </div>
      </div>
    );

  } else {
    return (

      <div className="home__container">
        <div className="home__wrap">
          <div className="home__texts">
            <div className="home__title">Communibility</div>
            <div className="home__description__box">
              <div className="home__description">
                A community that gives you the<span>  ability to earn </span>
              </div>
            </div>
          </div>

          <div className="nav__item__gold">
            <a
              href="https://discord.gg/6b2xYkvTWH"
              className="join-beta-button"
              onMouseOver={(e) => (e.target.textContent = "JOIN THE BETA")}
              onMouseOut={(e) => (e.target.textContent = e.target.dataset.originalText)}
              data-original-text="JOIN THE BETA"
            >
              <i className="uil"></i>JOIN THE BETA
            </a>
          </div>

        </div>
      </div >
    );
  }


};

export default Data;
