import React, { useEffect } from "react";
import "./footer.css";
import Cdesign from '../../assets/C_3d.png'
import Spline from '@splinetool/react-spline';


const Footer = () => {


  return (
    <div className="footer__container">
      <div className="footer__box__widget">
        <div className="footer__logo">
          <Spline scene="https://prod.spline.design/MWIhRplFYB1nfVKK/scene.splinecode" />
        </div>
        <div className="copyright__title">
          ©2023 Communibility. All Rights Reserved.
        </div>
      </div>
    </div >
  );
};

export default Footer;
